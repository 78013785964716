<div class="player-content" [class.mobile]="state.isMobile">
    <ng-container>
        <nav-sidebar *ngIf="!state.isMobile"></nav-sidebar>
        <mat-drawer-container class="page-content">
            <mat-drawer
                mode="over"
                [opened]="searchPanel.isOpen"
                (closedStart)="searchPanel.close()"
                [autoFocus]="false"
            >
                <search-slideout-panel></search-slideout-panel>
            </mat-drawer>
            <mat-drawer-content>
                <div class="page-wrapper" #scrollContainer>
                    <div class="space-wrapper">
                        <router-outlet></router-outlet>
                    </div>
                </div>
                <queue-sidebar
                    *ngIf="!state.isMobile"
                    [class.full-height]="shouldHideVideo"
                ></queue-sidebar>
            </mat-drawer-content>
        </mat-drawer-container>
    </ng-container>
</div>

<player-controls *ngIf="!state.isMobile"></player-controls>
<mobile-player-controls
    *ngIf="state.isMobile && !state.hideTabBar"
></mobile-player-controls>

<fullscreen-overlay
    [class.mobile]="state.isMobile"
    ngClass="strategy-{{ player.state.activePlaybackStrategy }}"
    [class.hide-video]="shouldHideVideo"
>
    <div class="player-container youtube-player" id="youtube-player"></div>
    <div class="player-container html5-player">
        <div class="img-container">
            <playback-control-button
                (play)="player.play()"
                (pause)="player.pause()"
                [playing]="player.isPlaying()"
            ></playback-control-button>
        </div>
    </div>
    <div class="player-container soundcloud-player"></div>
</fullscreen-overlay>

<loading-indicator [isVisible]="state.loading" class="overlay"></loading-indicator>
