import { ElementRef, Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class WebPlayerState {
    public loading = false;
    public isMobile = false;
    public hideTabBar = false;
    public scrollContainer: ElementRef<HTMLElement>;

    constructor(private route: ActivatedRoute) {
        this.isMobile =
            window.matchMedia && window.matchMedia('(max-width: 768px)').matches;
        this.route.queryParams.subscribe((params) => {
            this.hideTabBar = params.hideBar || false;
        });
    }
}
